<template>
  <div class="file-input-wrapper" :class="{ 'is-invalid': isInvalid, disabled: disabled, fillHeight, overflowScroll }">
    <vue-dropzone
      :ref="uniqueKey"
      :id="uniqueKey"
      :options="dropzoneOptions"
      :useCustomSlot="true"
      @vdropzone-error="handleError"
      @vdropzone-files-added="fileAdded"
      @vdropzone-drop="onFileDrop"
    >
      <div class="dropzone-content">
        <div v-if="file" :style="{ backgroundImage: thumbnail }" class="thumbnail"></div>
        <div v-else class="instructions">
          <slot name="instructions">
            <p>{{ tempAllowedFiles.join(', ') }}</p>
            <b-button size="sm" variant="primary" :disabled="disabled">{{ localizedTexts.selectFile }}</b-button>
          </slot>
        </div>
      </div>
    </vue-dropzone>
    <b-form-invalid-feedback :id="`dz-error-file-${uniqueKey}`" class="dz-file-error" :class="{ invalid: fileError }">{{
      fileError
    }}</b-form-invalid-feedback>

    <!-- Confirm modal -->
    <confirm ref="confirmModal" :title="localizedTexts.confirmTitle" :content="localizedTexts.confirmText" :close="confirmAction"></confirm>
  </div>
</template>

<style lang="scss" scoped>
@import './FileDropzoneInput.scss';
</style>

<script>
import categoriesSrv from '@/api/categories';
import filesThumb from '@/assets/img/icons/files.svg';
import pdfThumb from '@/assets/img/icons/pdfThumb.svg';
import Confirm from '@/components/Modals/Confirm/Confirm.vue';
import vue2Dropzone from 'vue2-dropzone';

export default {
  components: {
    vueDropzone: vue2Dropzone,
    Confirm,
  },

  props: {
    // Identifier
    uniqueKey: {
      type: String,
      default: 'dz-input',
    },

    fileFormName: String,

    value: [File, Array, String],

    isInvalid: {
      type: String,
      default: null,
    },

    // Options
    disabled: {
      type: Boolean,
      default: false,
    },

    removeOnConfirm: {
      type: Boolean,
      default: false,
    },

    fillHeight: {
      type: Boolean,
      default: false,
    },

    overflowScroll: {
      type: Boolean,
      default: false,
    },

    category_id: {
      type: Number,
      required: false,
    },

    nonImageFiles: {
      type: Array,
      default: () => [], // No '.'
    },
  },

  data() {
    return {
      fileError: false,
      localizedTexts: {
        selectFile: this.$t('forms.placeholders.selectFile'),
        files: this.$t('general.files'),
        eliminate: this.$t('general.eliminate'),
        dictInvalidFileType: this.$t('forms.validations.files.invalidFileType'),
        dictFileTooBig: this.$t('forms.validations.files.fileTooBig', { size: '500MB' }),
        confirmTitle: this.$t('general.confirm'),
        confirmText: this.$t('general.areYouSure'),
      },
      tempAllowedFiles: [],
      // file
      file: null,
      fileExtension: undefined,
      thumbnailIcons: {
        pdf: pdfThumb,
        // xls: xlsThumb (example)
      },
    };
  },

  computed: {
    fileUrl() {
      if (typeof this.value === 'string') return this.value;
      return undefined;
    },

    thumbnail() {
      const customExt = this.fileExtension ? this.fileExtension : this.getFileExt(this.fileUrl);
      return `url(${this.nonImageFiles.includes(customExt) ? this.thumbnailIcons[customExt] || filesThumb : this.fileUrl})`;
    },

    dropzoneOptions() {
      return {
        url: '/',
        maxFilesize: 500,
        autoProcessQueue: false,
        acceptedFiles: this.tempAllowedFiles.join(','),
        dictInvalidFileType: this.localizedTexts.dictInvalidFileType,
        dictFileTooBig: this.localizedTexts.dictFileTooBig,
        previewTemplate: this.dzTemplate,
        init() {
          this.on('addedfile', () => {});
        },
      };
    },

    dzThumbnailElem() {
      return document.querySelector(`#${this.uniqueKey} [data-dz-thumbnail]`);
    },

    dzTemplate() {
      return `
        <div>
          <div class="thumbnail" data-dz-thumbnail-bg></div>
        </div>`;
    },
  },

  methods: {
    openConfirmModal() {
      this.$refs.confirmModal.open();
    },

    async getCategory() {
      try {
        if (this.category_id !== null && this.category_id !== undefined) {
          const selectedCategory = await categoriesSrv.getCategory(this.category_id);
          this.tempAllowedFiles = selectedCategory.extensions.map((extension) => `.${extension.name}`);
        }
      } catch (error) {}
    },

    confirmAction(modal, response) {
      this.$bvModal.hide(modal);
      if (response) {
        this.removeFile();
      }
    },

    setThumbnail(thumbnail) {
      this.dzThumbnailElem.src = thumbnail;
    },

    getFileExt(file) {
      return file?.split('.')?.pop()?.toLowerCase();
    },

    handleError(error) {
      this.fileError = error?.previewElement?.innerText;
    },

    fileAdded(files) {
      const fileListArray = Array.from(files);
      fileListArray.forEach(file => {
        const reader = new FileReader();
        reader.onload = (evt) => {
          const base64 = evt.target.result;
          const fileData = {
            name: file.name,
            extension: this.getFileExt(file.name),
          };
          const binaryFile = new File([base64], file.name, { type: file.type });
          this.$emit('fileDataAdded', fileData);
          this.$emit('fileAdded', binaryFile);
        };
        reader.readAsArrayBuffer(file);
      });
    },

    onFileRemove() {
      this.removeFile();
      this.$emit('input', undefined);
      this.$refs[this.uniqueKey].removeAllFiles();
    },

    removeFile() {
      this.fileError = false;
      this.file = undefined;
      this.fileExtension = undefined;

      this.$emit('fileRemoved', this.value);
    },
  },
};
</script>
